/*
 * @Description:
 * @Version: 1.0
 * @Autor: silencc
 * @Date: 2020-11-13 14:58:21
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-10-22 13:31:22
 */

// import { parseTime } from '@/plugins/lib/modules/date'
// import { remit, getType } from '@/utils/options'
import { formStatus, getType } from '@/utils/options';

export var columns = function columns(ctx) {return [
  {
    label: '序号',
    type: 'index',
    width: 100 },

  {
    label: '消息内容',
    kname: 'content' },

  {
    label: '时间',
    kname: 'createAt',
    width: 200 },

  {
    label: '操作',
    kname: 'btns',
    width: 160 }];};




export var searchOption = [
{
  kname: 'startTime',
  label: '' },

{
  kname: 'endTime',
  label: '' }];