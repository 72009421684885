var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      attrs: {
        "append-to-body": "",
        "close-on-click-modal": false,
        title: _vm.title,
        visible: _vm.visible,
        width: _vm.width
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        },
        close: _vm.onClose
      }
    },
    [
      _c(
        "c-search",
        {
          attrs: { option: _vm.searchOption },
          on: { submit: _vm.getData },
          scopedSlots: _vm._u([
            {
              key: "startTime",
              fn: function(scope) {
                return [
                  _c(
                    "el-form-item",
                    _vm._b(
                      { attrs: { label: "开始时间：" } },
                      "el-form-item",
                      scope.data.itemProps,
                      false
                    ),
                    [
                      _c(
                        "el-date-picker",
                        _vm._g(
                          _vm._b(
                            {
                              staticStyle: { width: "220px" },
                              attrs: {
                                size: "small",
                                format: "yyyy-MM-dd",
                                placeholder: "请选择开始日期",
                                "picker-options": _vm.startTime,
                                type: "date",
                                "value-format": "yyyy-MM-dd 00:00:00"
                              },
                              model: {
                                value: _vm.query.startTime,
                                callback: function($$v) {
                                  _vm.$set(_vm.query, "startTime", $$v)
                                },
                                expression: "query.startTime"
                              }
                            },
                            "el-date-picker",
                            _vm.$attrs,
                            false
                          ),
                          _vm.$listeners
                        )
                      )
                    ],
                    1
                  )
                ]
              }
            },
            {
              key: "endTime",
              fn: function(scope) {
                return [
                  _c(
                    "el-form-item",
                    _vm._b(
                      { attrs: { label: "结束时间：" } },
                      "el-form-item",
                      scope.data.itemProps,
                      false
                    ),
                    [
                      _c(
                        "el-date-picker",
                        _vm._g(
                          _vm._b(
                            {
                              staticStyle: { width: "220px" },
                              attrs: {
                                size: "small",
                                format: "yyyy-MM-dd",
                                placeholder: "请选择结束日期",
                                "picker-options": _vm.endTime,
                                type: "date",
                                "value-format": "yyyy-MM-dd 23:59:59"
                              },
                              model: {
                                value: _vm.query.endTime,
                                callback: function($$v) {
                                  _vm.$set(_vm.query, "endTime", $$v)
                                },
                                expression: "query.endTime"
                              }
                            },
                            "el-date-picker",
                            _vm.$attrs,
                            false
                          ),
                          _vm.$listeners
                        )
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.query,
            callback: function($$v) {
              _vm.query = $$v
            },
            expression: "query"
          }
        },
        [
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    "native-type": "submit",
                    size: "small",
                    type: "primary"
                  }
                },
                [_vm._v(" 查询 ")]
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.handleReset }
                },
                [_vm._v(" 重置 ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("c-table", {
        attrs: {
          columns: _vm.columns,
          data: _vm.list,
          border: "",
          size: "mini",
          stripe: "",
          height: "auto"
        },
        scopedSlots: _vm._u([
          {
            key: "btns",
            fn: function(scope) {
              return [
                scope.row.read == 1
                  ? _c(
                      "el-button",
                      {
                        attrs: { size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.detail(scope.row)
                          }
                        }
                      },
                      [_vm._v("查看")]
                    )
                  : _vm._e(),
                scope.row.read == 0
                  ? _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.detail(scope.row)
                          }
                        }
                      },
                      [_vm._v("查看")]
                    )
                  : _vm._e()
              ]
            }
          }
        ])
      }),
      _c("c-pagination", {
        attrs: { slot: "footer", page: _vm.page, search: _vm.getData },
        slot: "footer"
      }),
      _c("FormDialog", { ref: "FormDialog", on: { refresh: _vm.getData } }),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small" },
              on: {
                click: function($event) {
                  _vm.visible = false
                }
              }
            },
            [_vm._v("取 消")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }